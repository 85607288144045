<template>
    <div class="mBottom">
        <li @click="clickNav(title,index)" v-for="(title,index) in navtitle" :key="index" :class="{ activeNav: title.path === isActive}">
                <i class="local-nav-icon" :class=title.icon></i>
                <span>{{ title.label }}</span>
        </li>

    </div>
</template>

<script>
export default {
    data() {
        return {
            selectedTabIndex: -1,
            navtitle: [
                {
                    path: '/product/networth',
                    name: 'networth',
                    label: '净值',
                    icon: 'el-icon-s-home'
                },
                {
                    path: '/product/investment',
                    name: 'investment',
                    label: '投资',
                    icon: 'el-icon-s-marketing'
                },
                {
                    path: '/product/incomeexpense',
                    name: 'IncomeExpense',
                    label: '收支',
                    icon: 'el-icon-s-management'
                },
                {
                    path: '/product/retireplan',
                    name: 'retireplan',
                    label: '退休',
                    // icon: 'el-icon-ship'
                    icon: 'el-icon-cold-drink'
                },
                {
                    path: '/product/user',
                    name: 'my',
                    label: '我的',
                    icon: 'el-icon-s-custom'
                },
            ],
        }
    },


    mounted() {
        // console.log("SET_isActiveIndex",this.$route.path)
        this.$store.commit("SET_isActiveIndex",this.$route.path)
    },

    computed: {
        isActive(){
            return this.$store.state.isActive;
        }
    },

    methods: {

        clickNav(item, index) {
            // this.selectedTabIndex = index;

            this.$store.commit("SET_isActiveIndex",item.path)
            if (this.$route.path !== item.path && !(this.$route.path === '/product/networth' && (item.path === '/product'))) {
                this.$router.push(item.path);
            }
        },

    }
}
</script>

<style scoped>

/*头部，包括导航页,用户中通知,消息和logo*/
.mBottom {
    border-top: 1px solid #F4F6FA;
    color: #8c939d;
    padding: 16px 0;
    display: flex;
}


.mBottom li {
    flex: 1;
    font-size: 0.8rem;
}

/*.mBottom li:hover {*/
/*    !*border-bottom: 2px solid #1047f7;*!*/
/*    color: var(--blue-1);*/
/*}*/



.mBottom li {
    display: flex;
    flex-direction: column;
    align-items: center;
    color: #8c939d;
}

.mBottom li.activeNav {
    color: var(--blue-2);
    /*color: var(--blue-1);*/
}
.local-nav-icon {
    font-size: 1.6rem;
}
</style>
