import Vue from 'vue';
import VueRouter from "vue-router";
import mHome from "../views/mHome.vue";

Vue.use(VueRouter)

const routes = [{
    path: '/', name: 'homepage', component: () => import('../views/Homepage/Homepage.vue')
}, {
    path: '/login', name: 'login', component: () => import('../views/Login/Login.vue'),
}, {
    path: '/wxlogin', name: 'wxlogin', component: () => import('../views/WechatLogin.vue'),
}, {
    path: '/register', name: 'register', component: () => import('../views/Login/Register.vue'),
}, {
    path: '/find', name: 'find', component: () => import('../views/Login/FindPswd.vue'),
}, {
    path: '/agreement', name: 'agreement', component: () => import('../views/Homepage/ServiceContract.vue')
}, {
    path: '/about', name: 'about', component: () => import('../views/Homepage/About.vue')
}, {
    path: '/contact', name: 'contact', component: () => import('../views/Homepage/Contact.vue')
}, {
    path: '/join', name: 'join', component: () => import('../views/Homepage/JoinUs.vue')
}, {
    path: '/price', name: 'price', component: () => import('../views/Homepage/Price.vue')
}, {
    path: '/step', name: 'step', component: () => import('../views/Login/mobileFirstUse.vue')
}, {
    path: '/firstadd', name: 'firstadd', component: () => import('../views/Login/NetWorthFirstAdd.vue'),
}, {
    path: '/product/', component: mHome, name: 'product', redirect: '/product/networth', //重定向
    children: [{
        path: 'networth', name: 'networth', component: () => import('../views/NetWorth/mNetWorth.vue'), // children: []
    }, {
        path: 'incomeexpense', name: 'incomeexpense', component: () => import('../views/IncomeExpense/mIncomeExpenseCalendar.vue')
    }, {
        path: 'retireplan', name: 'retireplan', component: () => import('../views/RetirePlan/mRetirePlan.vue')
    }, {
        path: 'investment', name: 'investment', component: () => import('../views/Investment/mInvestment.vue')
    }, {
        path: 'user', name: 'user', component: () => import('../views/User/mUser.vue')
    }],
}, {
    path: '/rpinform', name: 'rpinform', component: () => import('../views/RetirePlan/mRetirementIncomeForm.vue'),
},{
    path: '/rpintable', name: 'rpintable', component: () => import('../views/RetirePlan/mRetirementIncomeTable.vue'),
}, {
    path: 'nwdetail', name: 'nwdetail', component: () => import('../views/NetWorth/mNetWorthTable.vue'),
}, {
    path: 'nwform', name: 'nwform', component: () => import('../views/NetWorth/mNetWorthForm.vue'),
}, {
    path: 'nwadd', name: 'nwadd', component: () => import('../views/NetWorth/mNetWorthAdd.vue'),
}, {
    path: 'nwtransform', name: 'nwtransform', component: () => import('../views/NetWorth/mNetWorthTransform.vue'),
}, {
    path: 'ieadd', name: 'ieadd', component: () => import('../views/IncomeExpense/mIncomeExpenseAdd.vue'),
}, {
    path: 'rpedit', name: 'rpedit', component: () => import('../views/RetirePlan/mRetirementEditForm.vue'),
}, {
    path: 'rpexform', name: 'rpexform', component: () => import('../views/RetirePlan/mRetirementExpenseForm.vue'),
}, {
    path: 'rpextable',
    name: 'rpextable',
    component: () => import('../views/RetirePlan/mRetirementExpenseTable.vue'),
}, {
    path: 'investsell', name: 'investsell', component: () => import('../views/Investment/mInvestmentSell.vue'),
}, {
    path: 'searchbill', name: 'searchbill', component: () => import('../views/IncomeExpense/mIncomeExpenseSearch.vue'),
}, {
    path: 'contract', name: 'contract', component: () => import('../views/User/mServiceContract.vue')
},{
    path: 'advice', name: 'advice', component: () => import('../views/User/mAdvice.vue')
},


]

const router = new VueRouter({
    // require service support
    mode: 'history', routes // short for `routes: routes`
})

export default router

