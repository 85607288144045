<template>

    <div class="container">
        <div class="Section">
            <router-view></router-view>
        </div>

        <div class="mNav">
            <mNav></mNav>
        </div>
    </div>

</template>

<script>
import mNav from "@/components/mNav.vue";
import "../assets/css/base.css"
import "../assets/font/iconfont"

export default {
    data() {
        return {}
    },
    components: {
        mNav,
    },

    mounted() {
        history.pushState(null, null, document.URL);
        window.addEventListener('popstate', function () {
            history.pushState(null, null, document.URL);
        });
    },
}
</script>

<style scoped>

.container {
    /*display: flex;*/
    width: 100vw;
    height: 100vh;
    /*flex-direction: column;*/
    background: white;
}

.Section {
    width: 100%;
    /*flex: 1;*/
    height: calc(100% - 76px);
    overflow: auto;
}

.mNav{
    width: 100%;
    background: white;
    position: fixed;
    /*position: relative;*/
    left: 0px;
    bottom: 0px;
    /*height: 4.6rem;*/
    height: 5.6rem;
}

</style>
