import Vue from 'vue';
import App from './App.vue';
import './assets/font/iconfont.css';
import router from './router';
import store from "@/store";
import Cookies from "js-cookie";
import { getThousandsNum } from './utils/common'

import {hidden} from "@/utils/hidden";

import 'element-ui/lib/theme-chalk/index.css';
// import ElementUI from 'element-ui';
// Vue.use(ElementUI);
import {
    Dialog,
    Dropdown,
    DropdownMenu,
    DropdownItem,
    Input,
    Select,
    Progress,
    DatePicker,
    Table,
    TableColumn,
} from 'element-ui';
Vue.use(Dialog);
Vue.use(Dropdown);
Vue.use(DropdownMenu);
Vue.use(DropdownItem);
Vue.use(Input);
Vue.use(Select);
Vue.use(Table);
Vue.use(TableColumn);
Vue.use(Progress);
Vue.use(DatePicker);


Vue.config.productionTip = false;

Vue.filter('formatPrice', getThousandsNum)

Vue.filter('hideAmount', hidden)

import 'vant/lib/toast/style';
import { Toast,Cell,Overlay, Tab, Tabs, Search, Step, Steps, SwipeCell, Button, Form, Field, RadioGroup, Radio, Popup, Picker} from 'vant';

Vue.use(SwipeCell);
Vue.use(Button);
Vue.use(Form);
Vue.use(Field);
Vue.use(Radio);
Vue.use(RadioGroup);
Vue.use(Popup);
Vue.use(Picker);
Vue.use(Toast);
Vue.use(Cell);
Vue.use(Overlay);
Vue.use(Tab);
Vue.use(Tabs);
Vue.use(Search);
Vue.use(Step);
Vue.use(Steps);




// import Vant from 'vant';
// import 'vant/lib/index.css';
// Vue.use(Vant);

// router.beforeEach((to, from, next) => {
//     const token = Cookies.get('satoken');
//     // debugger;
//     // if (!token && (to.name !== 'login' || to.name !== 'register')) {
//     if (!token && to.name === 'register') {
//         next()
//     } else if (!token && to.name !== 'login') {
//         // debugger;
//         next({name: 'login'})
//     } else if(token && to.name === 'firstuse'){
//         next()
//     } else if (token && to.name === 'login') {
//         // debugger;
//         next({name: 'home'});
//     } else {
//         // debugger;
//         if (Cookies.get('isFirstLogin') === 'true') {
//             // console.log('isFirstLogin')
//             Cookies.remove('isFirstLogin')
//             next({name: 'firstuse'});
//         } else {
//             next();
//         }
//         // next();
//     }
// });

// router.beforeEach((to, from, next) => {
//     const token = Cookies.get('satoken');
//     // debugger;
//     // if (!token && to.name === 'register') {
//     //     next()
//     // }else
//
//     if (token && to.name === 'login') {
//         to.query.hold !== 'login' ? next({ name: 'product' }) : next()
//     }else if (!token && to.name !== 'login' && to.name !== 'register'
//         && to.name !== 'find' && to.name !== 'homepage'
//         && to.name !== 'agreement' && to.name !== 'about'
//         && to.name !== 'contact' && to.name !== 'join'
//         && to.name !== 'price') {
//         // next({name: 'login'})
//         next({name: 'homepage'})
//     }else {
//         next();
//     }
// });


// 请求isFirstLogin接口,不是第一次登入调转到firstuse界面
// debugger
//  isFirstLogin(token).then((resp)=>{
//     if (resp.data === true) {
//         next({name:'firstuse'});
//     }else{
//         next();
//     }
// });

/*        if(Cookies.get('isFirstLogin')){
            next({name:'firstuse'});
        }else {
            next();
        }*/

new Vue({
    el: '#app',
    router,
    store,
    render: h => h(App),
    // mounted() {
    //     document.dispatchEvent(new Event('render-event'))
    // },
}).$mount('#app')

